import "./App.css";

export default function App() {
  return (
    <div className="App">
      <div id="soon-text">
        <span>Soon</span>
        <span>tm</span>
      </div>
      <div id="hellbore-prison">
        {/* <img id="hellbore" src={`${BUCKET_API}HellboreBackground.png`} alt="" /> */}
      </div>
    </div>
  );
}
